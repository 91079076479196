import { FETCH_ROUTE, SET_ROUTE } from "../actions/RouteActions";


export const initialState = {
    success: false,
    loading: false,
    customColumn: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const RouteReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROUTE:
            return {
                ...state,
            };
        case SET_ROUTE: {
            return {
                ...state,
                success: true,
                loading: false,
                payload: action
            };
        }
        default:
            return state;
    }
};

export default RouteReducer;
