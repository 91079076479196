import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import storage from 'redux-persist/lib/storage/session';
const initialState = {};
const middlewares = [thunk];
let devtools = x => x;
if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}
const persistConfig = {
  key: 'root',
  storage,
}
const pReducer = persistReducer(persistConfig, RootReducer);
const Store = createStore(pReducer, initialState, compose(applyMiddleware(...middlewares) , devtools));
const persistor = persistStore(Store);
export { persistor, Store };
