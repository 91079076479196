import { base_url, toast, history } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_ACCOUNT = "INSERT_SUCCESS_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ADMIN_ACCOUNT = "GET_ADMIN_ACCOUNT";
export const GET_PARTNER_ACCOUNT = "GET_PARTNER_ACCOUNT";

export function insertAccountActions(userData, userAccount, userId) {
    userData.account_industry = userAccount
    userData.owner_id = userId?.owner_id
    return dispatch => {
        axios
            .post(`${base_url()}/api/v1/account`, userData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_ACCOUNT,
                        data: response,
                    })
                    toast.success(response.data.message);
                    userData?.opp_flag != 1 && history.push("/accounts")
                    if (userData?.opp_flag) {
                        userData.acc_formik()
                        userData.setShow()
                    }
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getAccount = (accArr) => ({
    type: GET_ACCOUNT,
    payload: accArr,
});

export function getAccountActions(query, page, limit, sortName, sortOrder, columnName, cardCount = "All account") {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/account/list?search_query=${query}&page=${page}&limit=${limit}&sort_name=${sortName}&sort_order=${sortOrder}&column_name=${columnName}&cardCount=${cardCount}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getAccount({ status: false, message: [], messageAllCount: 0, totalAllPage: 0 }))
                } else {
                    dispatch(getAccount(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getAccount({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getAdminAccount = (accAdminArr) => ({
    type: GET_ADMIN_ACCOUNT,
    payload: accAdminArr,
});

export function getAdminAccountActions(flagId) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/account/option/${flagId}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getAdminAccount({ status: false, message: [] }))
                } else {
                    dispatch(getAdminAccount(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getAdminAccount({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

// const getParnterAccount = (partnerList) => ({
//     type: GET_PARTNER_ACCOUNT,
//     payload: partnerList,
// });

// export function getParnterAccountActions() {
//     const cacheBuster = new Date().getTime();
//     return dispatch => {
//         axios
//             .get(`${base_url()}/api/v1/account/partner`, {
//                 headers: {
//                     'Cache-Control': 'no-cache',
//                     'Pragma': 'no-cache',
//                     'Expires': '0',
//                     'If-Modified-Since': '0',
//                 },
//                 withCredentials: true,
//                 credentials: 'include',
//                 params: { cache: cacheBuster }
//             })
//             .then(response => {
//                 if (response.status === 204) {
//                     dispatch(getParnterAccount({ status: false, message: [] }))
//                 } else {
//                     dispatch(getParnterAccount(response.data))
//                 }
//             }).catch(error => {
//                 if (error.response.status === 404 || error.response.status === 500) {
//                     dispatch(getParnterAccount({ status: false, message: [] }))
//                 } else if (error.response.status === 401) {
//                     toast.error(error.response.data)
//                 }
//             })
//     }
// }