import { lazy } from "react";
const Opportunity = lazy(() => import("./opportunity"));
const Add = lazy(() => import("./add"));
const Log = lazy(() => import("./log"));
const ShowQuota = lazy(() => import("./quotation"));
const AddQuota = lazy(() => import("./quotation/sale-quotation"));

const dealRoutes = [
    { exact: true, path: "/opportunity", component: Opportunity },
    { exact: true, path: "/opportunity/add", component: Add },
    { exact: true, path: "/opportunity/log", component: Log },
    { exact: true, path: "/quotation", component: ShowQuota },
    { exact: true, path: "/quotation/sale-quotation", component: AddQuota },
];

export default dealRoutes;
