import { lazy } from "react";
const Team = lazy(() => import('./team'));

const teamRoutes = [
    {
        exact: true,
        path: "/teams",
        component: Team,
    },
];

export default teamRoutes;
