import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const FETCH_ROUTE = 'FETCH_ROUTE';
export const SET_ROUTE = 'SET_ROUTE';
export const FETCH_CUSTOM_COLUMN = 'FETCH_CUSTOM_COLUMN';
export const SET_CUSTOM_COLUMN = 'SET_CUSTOM_COLUMN';
export const FETCH_PIPELINE_STAGE = 'FETCH_PIPELINE_STAGE';
export const SET_PIPELINE_STAGE = 'SET_PIPELINE_STAGE';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const FETCH_FEEDBACK = 'FETCH_FEEDBACK';


export function fetchRoute(pipeline) {
    return (dispatch) => {
        return new Promise(resolve => {
            setTimeout(() => {
                dispatch({ type: FETCH_ROUTE });
                resolve(dispatch({
                    type: SET_ROUTE,
                    data: pipeline
                }))
            }, 500)
        })
    }
}
export function fetchCustomColumn(pipe, tbl) {
    const cacheBuster = new Date().getTime();
    return (dispatch) => {
        if (!pipe) return;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                dispatch({ type: FETCH_CUSTOM_COLUMN });
                axios
                    .get(`${base_url()}/api/v1/custom/column/${pipe}/${tbl}`, {
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                            'If-Modified-Since': '0',
                        },
                        withCredentials: true,
                        credentials: 'include',
                        params: {
                            cache: cacheBuster,
                        }
                    })
                    .then(response => {
                        resolve(dispatch({
                            type: SET_CUSTOM_COLUMN,
                            data: response.status === 204 ? [] : response.data.message
                        }))
                    }).catch(error => {
                        if (error.response.status === 404 || error.response.status === 500) {
                            resolve(dispatch({ type: SET_CUSTOM_COLUMN, data: [] }))
                        } else if (error.response.status === 401) {
                            toast.error(error.response.data)
                        }
                    });
            }, 1000)
        })
    }
}
export function fetchPipelineStage(tkey, mkey) {
    const cacheBuster = new Date().getTime();
    return (dispatch) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                axios
                    .get(`${base_url()}/api/v1/pipeline/stage/${tkey}/${mkey}`, {
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                            'If-Modified-Since': '0',
                        },
                        withCredentials: true,
                        credentials: 'include',
                        params: {
                            cache: cacheBuster,
                        }
                    })
                    .then(response => {
                        dispatch({ type: FETCH_PIPELINE_STAGE });
                        resolve(dispatch({
                            type: SET_PIPELINE_STAGE,
                            data: response.status === 204 ? [] : response.data.message
                        }))
                    }).catch(error => {
                        if (error.response.status === 404 || error.response.status === 500) {
                            resolve(dispatch({ type: SET_PIPELINE_STAGE, data: [] }))
                        } else if (error.response.status === 401) {
                            toast.error(error.response.data)
                        }
                    });
            }, 500)
        })
    }
}
export function fetchFeedbackStage(dynamicValue, tbl, id) {
    const cacheBuster = new Date().getTime();
    return (dispatch) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                axios
                    .get(`${base_url()}/api/v1/lead/count/${id}/${tbl}?format=${id}&search_id=${dynamicValue}`, {
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                            'If-Modified-Since': '0',
                        },
                        withCredentials: true,
                        credentials: 'include',
                        params: {
                            cache: cacheBuster,
                        }
                    })
                    .then(response => {
                        dispatch({ type: FETCH_FEEDBACK });
                        resolve(dispatch({
                            type: SET_FEEDBACK,
                            data: response.status === 204 ? [] : response.data.message
                        }))
                    }).catch(error => {
                        if (error.response.status === 404 || error.response.status === 500) {
                            resolve(dispatch({ type: SET_FEEDBACK, data: [] }))
                        } else if (error.response.status === 401) {
                            toast.error(error.response.data)
                        }
                    });
            }, 500)
        })
    }
}