import React, { Component } from "react";
import Highlight from "react-highlight.js";
import ClipboardJS from 'clipboard';
import { initCodeViewer } from "@utils";

class CodeViewer extends Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
  }
  state = {};

  componentDidMount() {
    const pre = this.elem.current.refs.code.parentElement;

    // add/remove class to pre tag
    pre.classList.add('collapsed');    
    pre.addEventListener('click', (e) => {
      e.preventDefault();
      e.target.classList.remove('collapsed');
    });

    // Add copy button
    let copyButton = document.createElement("BUTTON");
    let copyButtonText = document.createTextNode("Copy");
    copyButton.appendChild(copyButtonText);
    copyButton.classList.add('btn', 'btn-sm', 'btn-success', 'btn-copy');
    pre.appendChild(copyButton);

    // Clipboard
    const clipboard = new ClipboardJS(copyButton, {
      text: (trigger) => {
        return this.elem.current.props.children;
      }
    });

    // Add event listener to change button text on copy
    clipboard.on('success', (e) => {
      copyButton.innerText = 'Copied...';
      setTimeout(() => {
        copyButton.innerText = 'Copy';
      }, 2000); // Change back to 'Copy' after 2 seconds
    });

    clipboard.on('error', (e) => {
      copyButton.innerText = 'Copy failed!';
      setTimeout(() => {
        copyButton.innerText = 'Copy';
      }, 2000); // Change back to 'Copy' after 2 seconds
    });
  }

  render() {
    return <div className="code-viewer">
      <Highlight ref={this.elem} {...this.props}>{this.props.children}</Highlight>
    </div>;
  }
}

export default CodeViewer;
