import { FETCH_PIPELINE_STAGE, SET_PIPELINE_STAGE } from "../actions/RouteActions";

export const initialState = {
    success: false,
    loading: false,
};

const PipelineReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PIPELINE_STAGE:
            return {
                ...state,
            };
        case SET_PIPELINE_STAGE: {
            return {
                ...state,
                success: true,
                loading: false,
                payload: action
            };
        }
        default:
            return state;
    }
};
export default PipelineReducer;
