import { lazy } from "react";
const Designation = lazy(() => import('./designation'));

const designationRoutes = [
    {
        exact: true,
        path: "/designations",
        component: Designation,
    },
];

export default designationRoutes;
