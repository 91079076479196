import { INSERT_SUCCESS_CAMPAIGN, GET_CAMPAIGN_TYPE } from "../actions/CampaignAction";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    campTypeArr: [],
    success: false,
    loading: true,
};

const CampaignReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_CAMPAIGN_TYPE:
            return {
                ...state,
                campTypeArr: action.payload,
                loading: false
            }

        case INSERT_SUCCESS_CAMPAIGN:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}
export default CampaignReducer;