import React from 'react'
import { Row, Col, Form } from "../../../base";
import CreatableSelect from 'react-select/creatable';

const MyCreatableSelect = ({ name, label, onChange, options, updateValue, status = false }) => {
    return (status ? <>
        <Form.Label>{label}<span className="text-danger">*</span></Form.Label>
        <CreatableSelect isClearable options={options} onChange={onChange} defaultValue={options.find(opt => opt.value === updateValue) || ""} name={name} />
    </> : <Col lg={6} md={6} sm={12}>
        <Row className="d-flex justify-content-between">
            <Col md={"4"}>
                <Form.Label>{label}</Form.Label>
            </Col>
            <Col md={"8"} className='mb-2'>
                <CreatableSelect isClearable options={options} onChange={onChange} name={name} />
            </Col>
        </Row>
    </Col>)
}

export default MyCreatableSelect