import React from 'react';
import { useHistory } from 'react-router-dom';

const Error401 = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/signin");
  };

  return (
    <div className="not-found-wrap text-center">
      <h1 className="text-60">401</h1>
      <span className="text-36 subheading mb-3">No Authorization Found.</span>
      <div className="mb-5  text-muted text-18">
        <p>This page is not publicly available.</p>
        <p>To access it please login first.</p>
      </div>
      <button onClick={handleClick} className="btn btn-lg btn-secondary px-5">
        Go back
      </button>
    </div>
  );
};

export default Error401;

// import React from 'react';
// import history from "@history.js";

// const Error401 = () => {
//   return (
//     <div className="not-found-wrap text-center">
//         <h1 className="text-60">401</h1>
//         <span className="text-36 subheading mb-3">No Authorization Found.</span>
//         <div className="mb-5  text-muted text-18">
//           <p>This page is not publically available.</p>
//           <p>To access it please login first.</p>
//         </div>
//         <button onClick={() => history("/signin")} className="btn btn-lg btn-secondary px-5">
//           Go back
//         </button>
//       </div>
//   )
// }

// export default Error401