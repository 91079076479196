import { lazy } from "react";
const Product = lazy(() => import('./product'));
const Manufacture = lazy(() => import('./manufacture'));
const Tax = lazy(() => import('./tax'));
const Unit = lazy(() => import('./unit'));
const PPG = lazy(() => import('./ParentProductGroup'));

const productRoutes = [
    { exact: true, path: "/product-group", component: PPG },
    { exact: true, path: "/product", component: Product },
    { exact: true, path: "/product/manufacture", component: Manufacture },
    { exact: true, path: "/product/tax", component: Tax },
    { exact: true, path: "/product/unit", component: Unit },
];

export default productRoutes;
