import {
  RESET_PASSWORD,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGN_OUT
} from "../actions/AuthActions";
const initialState = {
  success: false,
  loading: false,
  error: {
    email: null,
    password: null
  },
};

const AuthReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        payload: action
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: action
      };
    }
    case SIGN_OUT:
      return {
        success: false,
        loading: false,
        email: null,
      };
    default:
      return state;
  }

}

export default AuthReducer;