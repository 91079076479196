import { lazy } from "react";

const WhatsApp = lazy(() => import('./whatsapp'));

const whatsappRoutes = [
    {
        exact: true,
        path: "/whatsapp",
        component: WhatsApp,
    },
];

export default whatsappRoutes;
