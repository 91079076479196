import { FETCH_FEEDBACK, SET_FEEDBACK } from "../actions/RouteActions";


export const initialState = {
    success: false,
    loading: false,
    customColumn: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const FeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FEEDBACK:
            return {
                ...state,
            };
        case SET_FEEDBACK: {
            return {
                ...state,
                success: true,
                loading: false,
                payload: action
            };
        }
        default:
            return state;
    }
};

export default FeedbackReducer;
