import React from 'react'
import { Row, Col, Form } from "../../../base";

const MyTextarea = ({ name, label, value, onChange, rows, showStar, error, helperText }) => {
    return (<Row className='d-flex justify-content-end'>
        <Col md={2} sm={2}>
            <Form.Label>{label}{showStar ? <span className="text-danger">*</span> : null}</Form.Label>
        </Col>
        <Col md={10} sm={10} className='mb-2'>
            <textarea type="text" className={`form-control ${error ? "is-invalid" : ""}`} placeholder={label} name={name} onChange={onChange} rows={rows} value={value} ></textarea>
            {error && <Form.Control.Feedback type="invalid">{helperText}</Form.Control.Feedback>}
        </Col>
    </Row>)
}

export default MyTextarea