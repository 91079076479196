import { lazy } from "react";
const Ticket = lazy(() => import('./ticket'));
const Detail = lazy(() => import('./detail'));
const RCA = lazy(() => import('./rca'));

const ticketRoutes = [
    {
        exact: true,
        path: "/tickets",
        component: Ticket,
    },
    {
        exact: true,
        path: "/tickets/detail",
        component: Detail,
    },
    {
        exact: true,
        path: "/ticket/support",
        component: RCA,
    },
];

export default ticketRoutes;
