import { INSERT_SUCCESS_DEPARTMENT, GET_DEPARTMENTS, GET_LEADS, GET_LEAD_ADMIN, GET_OPPORTUNITY } from "../actions/DepartmentActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    depArr: [],
    leadArr: [],
    leadAdminArr: [],
    oppArr: [],
    success: false,
    loading: true,
};


const DepartmentReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_DEPARTMENTS:
            return {
                ...state,
                depArr: action.payload,
                loading: false
            }

        case GET_LEADS:
            return {
                ...state,
                leadArr: action.payload,
                loading: false
            }

        case GET_LEAD_ADMIN:
            return {
                ...state,
                leadAdminArr: action.payload,
                loading: false
            }

        case GET_OPPORTUNITY:
            return {
                ...state,
                oppArr: action.payload,
                loading: false
            }

        case INSERT_SUCCESS_DEPARTMENT:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default DepartmentReducer;