import { lazy } from "react";
const Role = lazy(() => import('./role'));
const Permission = lazy(() => import('./permission'));
const CompanyHoliday = lazy(() => import('./CompanyHoliday'));
const CompanyHour = lazy(() => import('./CompanyHour'));
const LoginAuthenticate = lazy(() => import('./LoginAuthenticate'));

const roleRoutes = [
    {
        exact: true,
        path: "/roles",
        component: Role,
    },
    {
        exact: true,
        path: "/permissions",
        component: Permission,
    },
    {
        exact: true,
        path: "/company-holiday",
        component: CompanyHoliday,
    },
    {
        exact: true,
        path: "/company-work-time",
        component: CompanyHour,
    },
    {
        exact: true,
        path: "/login-2-way-authenticate",
        component: LoginAuthenticate,
    },
];

export default roleRoutes;
