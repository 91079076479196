import { lazy } from "react";
const Task = lazy(() => import("./task"));
const Add = lazy(() => import("./add"));
const Edit = lazy(() => import("./edit"));
const Priority = lazy(() => import("./plog"));
const TaskType = lazy(() => import("./Type"));

const taskRoutes = [
    {
        exact: true,
        path: "/task",
        component: Task,
    },
    {
        exact: true,
        path: "/task/add",
        component: Add,
    },
    {
        exact: true,
        path: "/task/edit",
        component: Edit,
    },
    {
        exact: true,
        path: "/task/plog",
        component: Priority,
    },
    {
        exact: true,
        path: "/task/task-type",
        component: TaskType,
    },
];

export default taskRoutes;
