import {
    GET_SUCCESS_ACTIVE_PRODUCT, INSERT_SUCCESS_MANUFACTURE, GET_SUCCESS_MANUFACTURE, INSERT_SUCCESS_TAX, GET_SUCCESS_TAX, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_ERROR
} from "../actions/ProductActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    proArr: [],
    actProArr: [],
    manuArr: [],
    taxArr: [],
    success: false,
    loading: true,
    pending: false,
    error: null
};


const ProductReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SUCCESS_ACTIVE_PRODUCT:
            return {
                ...state,
                actProArr: action.payload,
                loading: false
            }
        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                pending: false,
                proArr: action.payload
            }
        case FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        //manufacture
        case INSERT_SUCCESS_MANUFACTURE:
            return {
                ...state,
                loading: false,
            };

        case GET_SUCCESS_MANUFACTURE:
            return {
                ...state,
                manuArr: action.payload,
                loading: false
            }

        // tax
        case INSERT_SUCCESS_TAX:
            return {
                ...state,
                loading: false,
            };

        case GET_SUCCESS_TAX:
            return {
                ...state,
                taxArr: action.payload,
                loading: false
            }
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}
export default ProductReducer;