import React, { memo } from 'react'
import { Row, Col, Form } from "../../../base";

const MySelect = React.forwardRef(({ label, options, valueKey, labelKey, error, helperText, showStar, ...props }, ref) => (
    <Col lg={6} md={6} sm={12}>
        <Form.Group>
            <Row className="d-flex justify-content-between">
                <Col md="4">
                    <Form.Label>
                        {label}{showStar ? <span className="text-danger">*</span> : null}
                    </Form.Label>
                </Col>
                <Col md="8" className='mb-2'>
                    <Form.Control as="select" {...props} isInvalid={error}>
                        <option value="">Select </option>
                        {Array.isArray(options) && options.map(option => (<option key={option[valueKey]} value={option[valueKey]}>{option[labelKey]}</option>))}
                    </Form.Control>
                    {helperText && <Form.Control.Feedback type="invalid">{helperText}</Form.Control.Feedback>}
                </Col>
            </Row>
        </Form.Group>
    </Col>
));

export default memo(MySelect)