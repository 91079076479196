import { lazy } from "react";
const Campaign = lazy(() => import("./campaign"));
const Detail = lazy(() => import("./detail"));
const Add = lazy(() => import("./add"));

const campaignRoutes = [
    {
        exact: true,
        path: "/campaign",
        component: Campaign
    },
    {
        exact: true,
        path: "/campaign/detail",
        component: Detail
    },
    {
        exact: true,
        path: "/campaign/add",
        component: Add
    }
];

export default campaignRoutes;
