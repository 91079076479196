import { lazy } from "react";
const Lead = lazy(() => import("./lead"));
const Add = lazy(() => import("./add"));
const Detail = lazy(() => import('./detail'));

const leadRoutes = [
    {
        exact: true,
        path: "/lead",
        component: Lead,
    },
    {
        exact: true,
        path:"/lead/add",
        component: Add,
    },
    {
        exact: true,
        path:"/lead/detail",
        component: Detail,
    },   
  
];

export default leadRoutes;
