import { lazy } from "react";
const Analytics = lazy(() => import("./"));

const analyticRoutes = [
  {
    exact: true,
    path: "/web-analytics",
    component: Analytics,
  },
];

export default analyticRoutes;
