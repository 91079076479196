import { lazy } from "react";
const Word = lazy(() => import("./word"));
const Plugin = lazy(() => import("./plugin"));
const Integration = lazy(() => import("./integration"));

const wordRoutes = [
    {
        exact: true,
        path: "/word",
        component: Word,
    },
    {
        exact: true,
        path: "/plugin_integration",
        component: Plugin,
    },
    {
        exact: true,
        path: "/integration",
        component: Integration,
    },
    
];

export default wordRoutes;
