import { FETCH_CUSTOM_COLUMN, SET_CUSTOM_COLUMN } from "../actions/RouteActions";

export const initialState = {
    success: false,
    loading: false,
};

const CustomReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOM_COLUMN:
            return {
                ...state,
            };
        case SET_CUSTOM_COLUMN: {
            return {
                ...state,
                success: true,
                loading: false,
                payload: action
            };
        }
        default:
            return state;
    }
};

export default CustomReducer;