import { lazy } from "react";
const SaleInvoice = lazy(() => import('./SaleInvoice'));
const SaleForm = lazy(() => import("./FormSale"));

const salesRoutes = [
    {
        exact: true,
        path: "/sale-invoice",
        component: SaleInvoice
    },
    {
        exact: true,
        path: "/sales-add-form",
        component: SaleForm
    },
];
export default salesRoutes;