import { GET_SUCCESS_DEPARTMENT_ASSIGN, GET_SUCCESS_TEAM_ASSIGN, GET_SUCCESS_EXECUTIVE_ASSIGN } from "../actions/AssignActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    assignDepartmentArr: [],
    assignTeamArr: [],
    assignExeArr: [],
    success: false,
    loading: true,
};


const AssignReducer = function (state = initialState, action) {

    switch (action.type) {

        case GET_SUCCESS_DEPARTMENT_ASSIGN:
            return {
                ...state,
                assignDepartmentArr: action.payload,
                loading: false
            }

        case GET_SUCCESS_TEAM_ASSIGN:
            return {
                ...state,
                assignTeamArr: action.payload,
                loading: false
            }

        case GET_SUCCESS_EXECUTIVE_ASSIGN:
            return {
                ...state,
                assignExeArr: action.payload,
                loading: false
            }
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default AssignReducer;