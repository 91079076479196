import { lazy } from "react";
const Account = lazy(() => import("./account"));
const Add = lazy(() => import("./add"));
const View = lazy(() => import("./view"));

const accountRoutes = [
    {
        exact: true,
        path: "/accounts",
        component: Account,
    },
    {
        exact: true,
        path: "/accounts/add",
        component: Add,
    },
    {
        exact: true,
        path: "/accounts/view",
        component: View,
    },
];

export default accountRoutes;
