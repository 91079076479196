import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_CAMPAIGN = "INSERT_SUCCESS_CAMPAIGN";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGN_TYPE = "GET_CAMPAIGN_TYPE";

export function insertCampaignActions(data, userCheck, userId) {
    return dispatch => {
        const campData = {
            owner_id: userId.owner_id,
            campaign_type_id: data.campaign_type_id,
            campaign_name: data.campaign_name,
            campaign_status: data.campaign_status,
            start_date: data.start_date,
            end_date: data.end_date,
            expected_revenue: data.expected_revenue || "",
            budgeted_cost: data.budgeted_cost || "",
            actual_cost: data.actual_cost || "",
            expected_response: data.expected_response || "",
            actual_response: data.actual_response || "",
            frequency: data.frequency || "",
            frequency_list: data.frequency_list || "",
            repeat_time: data.repeat_time || "",
            number_sent: data.number_sent || "",
            description: data.description,
            base64File: data?.base64File,
            fileName: data?.fileName,
            campaign_active: userCheck?.campaign_active || userCheck,
        }
        axios.post(`${base_url()}/api/v1/campaign`, campData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.status === 201) {
                    dispatch({
                        type: INSERT_SUCCESS_CAMPAIGN,
                        data: response,
                    })
                    toast.success(response?.data.message);
                    data.setShow(false)
                    data.resetForm()
                } else { toast.error(response?.data.message); }
            }).catch(error => {
                toast.error(error.response?.data.message)
            })
    }
}

const getCampaginType = (campTypeArr) => ({
    type: GET_CAMPAIGN_TYPE,
    payload: campTypeArr,
});

export function getCampaginTypeActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/api/v1/type/campaign/active`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getCampaginType(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getCampaginType([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}