
const ImportDynamicColumnKey = (oldDataArr, unmatchedColumns, targetColumns) => {
    return oldDataArr.map(item => {
        const updatedItem = {};
        unmatchedColumns.forEach((unmatchedColumn, index) => {
            const targetColumn = targetColumns[index];
            const originalValue = item[unmatchedColumn];
            updatedItem[targetColumn] = originalValue !== undefined ? originalValue : '';
        });
        return updatedItem;
    });
}

export default ImportDynamicColumnKey