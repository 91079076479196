import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import GullLayout from "app/GullLayout/GullLayout";
import { flatMap } from "lodash";
import PropTypes from "prop-types";
import { loginWithEmailAndPassword } from "../redux/actions/AuthActions";
import Error401 from "app/views/admin/Error401";

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    this.state = {
      user_status: false,
      authenticated: true,
      routes,
      navi_user: [],
      get_owner_id: "",
      auth_role: "",

      path_arr: [
        { path: "/task/edit" },
        { path: "/task/add" },
        { path: "/task/plog" },
        { path: "/lead/add" },
        { path: "/lead/detail" },
        { path: "/contacts/add" },
        { path: "/contacts/view" },
        { path: "/opportunity/add" },
        { path: "/opportunity/log" },
        { path: "/products/add" },
        { path: "/accounts/add" },
        { path: "/accounts/view" },
        { path: "/executives/add" },
        { path: "/tickets/detail" },
        { path: "/profile" },
        { path: "/users" },
        { path: "/executives" },
        { path: "/departments" },
        { path: "/teams" },
        { path: "/designations" },
        { path: "/feedback/opportunity-feedback" },
        { path: "/feedback/task-feedback" },
        { path: "/feedback/ticket-feedback" },
        { path: "/reports" },
        { path: "/reports/feedback-performance-report" },
        { path: "/reports/executive-performance-report" },
        { path: "/reports/product-list-report" },
        { path: "/reports/customer-list-report" },
        { path: "/reports/product-performance-report" },
        { path: "/reports/user-activity-report" },
        { path: "/reports/user-login-report" },
        { path: "/reports/ticket-monitor" },
        { path: "/reports/opportunity-report" },
        { path: "/reports/executive-opportunity-report" },
        { path: "/reports/lead-convert-report" },
        { path: "/reports/campaign-report" },
        { path: "/reports/task-report" },
        { path: "/reports/task-monitor" },
        { path: "/reports/opportunity-monitor" },
        { path: "/reports/opportunity-summary-report" },
        { path: "/reports/working-hours" },
        { path: "/notification-all" },
        { path: "/terms-conditions" },
        { path: "/sale-invoice" },
        { path: "/sales-add-form" },
        { path: "/order" },
        { path: "/order/sale-order" },
        { path: "/quotation" },
        { path: "/quotation/sale-quotation" },
        { path: "/email_configuration" },
        { path: "/whatsapp" },
        { path: "/message-template" },
        { path: "/notification" },
        { path: "/permissions" },
        { path: "/company-holiday" },
        { path: "/company-work-time" },
        { path: "/feedback-configuration" },
        { path: "/terms-conditions" },
        { path: "/numbering-configuration" },
        { path: "/financial-year" },
        { path: "/integration" },
        { path: "/product/manufacture" },
        { path: "/product/tax" },
        { path: "/product/unit" },
        { path: "/product-group" },
        { path: "/product" },
        { path: "/task/task-type" },
        { path: "/task" },
        { path: "/tickets" },
        { path: "/ticket/support" },
        { path: "/campaign" },
        { path: "/campaign/add" },
        // { path: "/orderinvoice?p=" },
        // { path: "/quotationinvoice?p=" },
      ],
    };
  }
  authRole = this.props.get_owner_id?.data.datas.role_id;

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, item => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      })
    });

    if (this.props.get_owner_id) {
      if (this.props.get_owner_id.data.dataType) {
        let dataStore = this.props.get_owner_id.data.dataType;
        let newArr = dataStore.filter((ele, ind) => ind === dataStore.findIndex(elem => elem.navigation_id === ele.navigation_id))
        if (newArr.length > 0) {
          this.setState({ navi_user: newArr })
        } else {
          this.setState({ navi_user: [] })
        }
      }
    }

    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  openAddBoardModal = () => {
    this.setState({ "user_status": true })
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props, state) {
    const { location, user } = props;
    const { pathname } = location;
    let authRole = props.get_owner_id?.data.datas?.role_id
    if (authRole === 1) {
      const matched = state.routes.find(r => r.path === pathname);
      const authenticated = matched && matched.auth && matched.auth.length
        ? matched.auth.includes(user.role)
        : true;
      return { authenticated };
    } else {
      let path = window.location.pathname;
      let path_checker = state.navi_user.map(cur => cur.type === "dropDown" ? cur.sub : []);
      let sub_val = path_checker?.map(cur => cur?.length > 0 ? cur.find(r_sub => r_sub.path === pathname) : "")
      if (sub_val[0]) {
        const authenticated = sub_val && sub_val.auth && sub_val.auth.length
          ? sub_val.auth.includes(user.role)
          : sub_val || path === "/dashboard" || path === "/" || state.path_arr.find(val => val.path === path) ? true : false;
        return { authenticated }
      } else {
        const matched = state.navi_user.find(r => r.path === pathname);
        const authenticated = matched && matched.auth && matched.auth.length
          ? matched.auth.includes(user.role)
          : matched || path === "/dashboard" || path === "/" || state.path_arr.find(val => val.path === path) ? true : false;
        return { authenticated }
      }
    }
  }

  redirectRoute(props) {
    // const { location, history } = props;
    // const { pathname } = location;
    // history.push({
    //   pathname: "/signin",
    //   state: { redirectUrl: pathname }
    // });
  }

  render() {
    let { route } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>
        <GullLayout route={route} />
      </Fragment>
    ) : <Error401 />
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = state => {
  return {
    user: state.user,
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    get_owner_id: state.login.payload,
  }
};

export default withRouter(connect(mapStateToProps, {
  loginWithEmailAndPassword,
})(AuthGuard));
