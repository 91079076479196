import { lazy } from "react";
const Contact = lazy(() => import("./contact"));
const Add = lazy(() => import("./add"));
const View = lazy(() => import("./view"));

const contactsRoutes = [
    {
        exact: true,
        path: "/contacts",
        component: Contact,
    },
    {
        exact: true,
        path: "/contacts/add",
        component: Add,
    },
    {
        exact: true,
        path: "/contacts/view",
        component: View,
    },
   
];

export default contactsRoutes;
