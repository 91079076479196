import React from 'react'
import MySelect from './MySelect'

const MyStage = ({ formik, name }) => {
    return (<>
        {/* <MySelect label={"Status"} name={name} value={formik.values[name]} options={pipeList || []} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched[name] && Boolean(formik.errors[name])} helperText={formik.touched[name] && formik.errors[name]} showStar={true} valueKey={"stage_id"} labelKey={"stagename"} /> */}
    </>)
}

export default MyStage
