
import CryptoJS from 'crypto-js';

export const encryptAndReplace = (text, maxRetries = 10) => {
    let ciphertext;
    let attempts = 0;
    const secretKey = 'Y:F=lgOERH^890*';

    do {
        if (attempts > maxRetries) {
            throw new Error('Max encryption attempts reached, could not generate ciphertext without "+"');
        }
        ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
        attempts++;
    } while (ciphertext.includes('+'));

    return ciphertext;
};