import { INSERT_SUCCESS_ACCOUNT, GET_ACCOUNT, GET_ADMIN_ACCOUNT, GET_PARTNER_ACCOUNT } from "../actions/AccountActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    accArr: [],
    accAdminArr: [],
    partnerList: [],
    success: false,
    loading: true,
};


const AccountReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_ACCOUNT:
            return {
                ...state,
                accArr: action.payload,
                loading: false
            }

        case GET_ADMIN_ACCOUNT:
            return {
                ...state,
                accAdminArr: action.payload,
                loading: false
            }

        // case GET_PARTNER_ACCOUNT:
        //     return {
        //         ...state,
        //         partnerList: action.payload,
        //         loading: false
        //     }

        case INSERT_SUCCESS_ACCOUNT:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default AccountReducer;