import { FETCH_AUTH_DOCUMENT_LIST } from "../actions/DocumentActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    docList: [],
    success: false,
    loading: true,
};


const DocumentReducer = function (state = initialState, action) {

    switch (action.type) {
        case FETCH_AUTH_DOCUMENT_LIST:
            return {
                ...state,
                docList: action.payload,
                loading: false
            }
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}
export default DocumentReducer;