import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import designationRoutes from "./views/designations/designationRoutes";
import executiveRoutes from "./views/executives/executiveRoutes";
import teamRoutes from "./views/teams/teamRoutes";
import departmentRoutes from "./views/departments/departmentRoutes";
import userRoutes from "./views/users/userRoutes";
// import assignTeamRoutes from "./views/assignTeams/assignTeamRoutes";
import leadRoutes from "./views/lead/leadRoutes";
import taskRoutes from "./views/tasks/taskRoutes";
import contactsRoutes from "./views/contacts/contactsRoutes";
import accountRoutes from "./views/accounts/accountRoutes";
import ticketRoutes from "./views/tickets/ticketRoutes";
import campaignRoutes from "./views/campaigns/campaignRoutes";
import dealRoutes from "./views/opportunities/dealRoutes";
import productRoutes from "./views/products/productRoutes";
import loyaltyRoutes from "./views/loyalties/loyaltyRoutes";
import emailRoutes from "./views/emails/emailRoutes";
import whatsappRoutes from "./views/whatsapp/whatsappRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/admin/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import chartsRoute from "./views/charts/chartsRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import profileRoutes from "./views/profiles/profileRoutes";
import wordRoutes from "./views/words/wordRoutes";
import calendarsRoutes from "./views/calendars/calendarsRoutes";
import roleRoutes from "./views/roles/roleRoutes";
import reportRoutes from "./views/reports/reportRoutes";
import chatRoutes from "./views/chat/chatRoutes";
import salesRoutes from "./views/saleInvoice/salesRoutes";
import dealerRoutes from "./views/dealers/dealerRoutes";
import orderRoutes from "./views/orders/orderRoutes";
import configRoute from "./views/config/configRoutes";
import WebAnalytics from "./views/plugin-analytics/analyticRoutes";
import documentRoute from "./views/documents/documentRoutes";
import financeRoute from "./views/financial_year/financeRoutes";

const redirectRoute = [
  {
    path: "/",
    component: () => <Redirect to="/signin" />
  },
  {
    path: '/board',
    exact: true,
    component: () => <Redirect to="/board" />
  },
];

const errorRoute = [
  { component: () => <Redirect to="/404" /> },
  { component: () => <Redirect to="/401" /> },
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...designationRoutes,
      ...executiveRoutes,
      ...teamRoutes,
      ...departmentRoutes,
      ...userRoutes,
      // ...assignTeamRoutes,
      ...leadRoutes,
      ...taskRoutes,
      ...contactsRoutes,
      ...accountRoutes,
      ...ticketRoutes,
      ...campaignRoutes,
      ...dealRoutes,
      ...wordRoutes,
      ...productRoutes,
      ...profileRoutes,
      ...loyaltyRoutes,
      ...emailRoutes,
      ...whatsappRoutes,
      ...formsRoutes,
      ...chartsRoute,
      ...extraKitsRoutes,
      ...calendarRoutes,
      ...calendarsRoutes,
      ...roleRoutes,
      ...reportRoutes,
      ...chatRoutes,
      ...salesRoutes,
      ...dealerRoutes,
      ...configRoute,
      ...WebAnalytics,
      ...documentRoute,
      ...financeRoute,
      ...orderRoutes,
      ...redirectRoute,

      ...errorRoute,
    ]
  }
];

export default routes;