// Validation function to handle focus on the first invalid field
export const validateAndFocus = async (formik, setExpandedPanel = null, fieldToPanelMap = null) => {
    const errors = await formik.validateForm();
    if (errors) {
        const errorField = Object.keys(errors)[0];
        const errorElement = document.getElementsByName(errorField)[0];

        if (setExpandedPanel && fieldToPanelMap) {
            const errorPanel = fieldToPanelMap[errorField];
            if (errorElement && errorPanel) {
                setExpandedPanel(prev => ({ ...prev, [errorPanel]: true }));
                errorElement.focus();
            }
        } else if (errorElement) {
            errorElement.focus();
        }
    }
};
