import { lazy } from "react";
const User = lazy(() => import('./user'));

const userRoutes = [
    {
        exact: true,
        path: "/users",
        component: User,
    },
];
export default userRoutes;