import { lazy } from "react";
const Profile = lazy(() => import('./profile'));

const profileRoutes = [
    {
        exact: true,
        path: "/profile",
        component: Profile,
    },
];

export default profileRoutes;
