import { INSERT_SUCCESS_DESIGNATION, GET_SUCCESS_DESIGNATION } from "../actions/DesigntaionActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    desArr: [],
    success: false,
    loading: true,
};

const DesignationReducer = function (state = initialState, action) {

    switch (action.type) {

        case GET_SUCCESS_DESIGNATION:
            return {
                ...state,
                desArr: action.payload,
                loading: false
            }

        case INSERT_SUCCESS_DESIGNATION:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}
export default DesignationReducer;