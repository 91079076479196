import { lazy } from "react";
const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));
const Notification = lazy(() => import("./dashboard1/Notification"));

const dashboardRoutes = [
  {
    exact: true,
    path: "/dashboard",
    component: Dashboard1,
  },
  {
    exact: true,
    path: "/notification-all",
    component: Notification,
  },
  
];

export default dashboardRoutes;
