import { lazy } from "react";
const Visits = lazy(() => import("./visits/visit"));
const Add = lazy(() => import("./visits/add"));
const Point = lazy(() => import("./points/point"));
const Addp = lazy(() => import("./points/add"));
const Sale = lazy(() => import("./sales/sale"));
const Adds = lazy(() => import("./sales/add"));
const Loyalty = lazy(() => import("./Loyalty"));

const loyaltyRoutes = [
    {
        exact: true,
        path: "/visits",
        component: Visits
    },
    {
        exact: true,
        path: "/visits/add",
        component: Add
    },
    {
        exact: true,
        path: "/points",
        component: Point
    },    
    {
        exact: true,
        path: "/points/add",
        component: Addp
    },    
    {
        exact: true,
        path: "/sales",
        component: Sale
    },    
    {
        exact: true,
        path: "/sales/add",
        component: Adds
    },
    {
        exact: true,
        path: "/loyalty",
        component: Loyalty
    },
];
export default loyaltyRoutes;