import { INSERT_SUCCESS_SUB_USER, GET_SUCCESS_SUB_USER, GET_SUCCESS_ROLE } from "../actions/SubUserActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    subUserArr: [],
    roleArr: [],
    success: false,
    loading: true,
};


const SubUserReducer = function (state = initialState, action) {

    switch (action.type) {

        case GET_SUCCESS_SUB_USER:
            return {
                ...state,
                subUserArr: action.payload,
                loading: false
            }

        case GET_SUCCESS_ROLE:
            return {
                ...state,
                roleArr: action.payload,
                loading: false
            }


        case INSERT_SUCCESS_SUB_USER:
            return {
                ...state,
                loading: false,
                success: true
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default SubUserReducer;