import { lazy } from "react";
const IR = lazy(() => import("./"))
const FPR = lazy(() => import("./other/FeedbackPerformanceReport"))
const ER = lazy(() => import("./other/ExecutiveReport"))
const PLR = lazy(() => import("./other/ProductListReport"))
const CR = lazy(() => import("./other/CustomerReport"))
const PPR = lazy(() => import("./other/ProductPerformanceReport"))
const ULR = lazy(() => import("./other/UserLoginReport"))
const UAR = lazy(() => import("./other/UserActivityReport"))
const TM = lazy(() => import("./other/TicketMonitor"))
const TKM = lazy(() => import("./other/TaskMonitorReport"))
const OM = lazy(() => import("./other/OpportunityMonitor"))
const OR = lazy(() => import("./other/OpportunityReport"))
const EOR = lazy(() => import("./other/ExecutiveOpportunityReport"))
const LCR = lazy(() => import("./other/LeadConvertReport"))
const CAR = lazy(() => import("./other/CampaignReport"))
const CPS = lazy(() => import("./loyalty/CustomerPointSummary"))
const CSS = lazy(() => import("./loyalty/CustomerSaleSummary"))
const CVS = lazy(() => import("./loyalty/CustomerVisitSummary"))
const PWS = lazy(() => import("./loyalty/PlanWiseSummary"))
const TF = lazy(() => import("./feedback/TicketFeedback"))
const TFB = lazy(() => import("./feedback/TaskFeedback"))
const OF = lazy(() => import("./feedback/OpportunityFeedback"))
const TR = lazy(() => import("./other/TaskReport"))
const OSR = lazy(() => import("./other/OpportunitySummaryReport"))
const PWSR = lazy(() => import("./other/ProductWiseOppReport"))
const WHR = lazy(() => import("./other/WorkingHoursReport"))
const LICR = lazy(() => import("./other/LicenseConversionReport"))

const reportRoutes = [
    { exact: true, path: "/reports", component: IR },
    { exact: true, path: "/reports/feedback-performance-report", component: FPR },
    { exact: true, path: "/reports/executive-performance-report", component: ER },
    { exact: true, path: "/reports/product-list-report", component: PLR },
    { exact: true, path: "/reports/customer-list-report", component: CR },
    { exact: true, path: "/reports/product-performance-report", component: PPR },
    { exact: true, path: "/reports/user-login-report", component: ULR },
    { exact: true, path: "/reports/user-activity-report", component: UAR },
    { exact: true, path: "/reports/ticket-monitor", component: TM },
    { exact: true, path: "/reports/task-monitor", component: TKM },
    { exact: true, path: "/reports/task-report", component: TR },
    { exact: true, path: "/reports/opportunity-monitor", component: OM },
    { exact: true, path: "/reports/opportunity-report", component: OR },
    { exact: true, path: "/reports/executive-opportunity-report", component: EOR },
    { exact: true, path: "/reports/lead-convert-report", component: LCR },
    { exact: true, path: "/reports/campaign-report", component: CAR },
    { exact: true, path: "/reports/working-hours", component: WHR },
    { exact: true, path: "/reports/customer-point-report", component: CPS },
    { exact: true, path: "/reports/customer-sale-report", component: CSS },
    { exact: true, path: "/reports/customer-visit-report", component: CVS },
    { exact: true, path: "/reports/plan-wise-report", component: PWS },
    { exact: true, path: "/feedback/ticket-feedback", component: TF },
    { exact: true, path: "/feedback/task-feedback", component: TFB },
    { exact: true, path: "/feedback/opportunity-feedback", component: OF },
    { exact: true, path: "/reports/opportunity-summary-report", component: OSR },
    { exact: true, path: "/reports/product-wise-opportunity-report", component: PWSR },
    { exact: true, path: "/reports/opportunity-conversion-report", component: LICR },
];

export default reportRoutes;