import { lazy } from "react";
const Executive = lazy(() => import('./executive'));
const Add = lazy(() => import('./add'));

const executiveRoutes = [
    {
        exact: true,
        path: "/executives",
        component: Executive,
    },
    {
        exact: true,
        path: "/executives/add",
        component: Add,
    },
];

export default executiveRoutes;
