import { lazy } from "react";
const Calendar = lazy(() => import("./calendar"));
const calendarsRoutes = [
    {
        exact: true,
        path: "/events",
        component: Calendar,
    },
    
];
export default calendarsRoutes;