import { INSERT_SUCCESS_CONTACT, GET_CONTACT, GET_ADMIN_CONTACT } from "../actions/ContactActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    conArr: [],
    conAdminArr: [],
    success: false,
    loading: true,
};


const AccountReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACT:
            return {
                ...state,
                conArr: action.payload,
                loading: false
            }

        case GET_ADMIN_CONTACT:
            return {
                ...state,
                conAdminArr: action.payload,
                loading: false
            }

        case INSERT_SUCCESS_CONTACT:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default AccountReducer;