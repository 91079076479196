import React, { lazy, memo } from 'react'
import { Form } from "../../../base";
import InputGroup from 'react-bootstrap/InputGroup';
const FlagCode = lazy(() => import("./FlagCode"))

const MySingleInput = ({ name, label, value, onChange, error, helperText, disabled, showStar, type, min = "", flag, flags = false, formik, placeholder }) => {
    return <>
        <Form.Group>
            {flag != 1 && <label>{label}{showStar && <span className="text-danger">*</span>}</label>}
            <InputGroup className='mb-1'>
                {flags && <FlagCode formik={formik} name={name} flags={flags} />}
                <Form.Control type={type || "text"} className={`form-control ${error ? 'is-invalid' : ''}`} min={min} style={{ flex: 1 }} placeholder={placeholder || label} disabled={disabled || false} onChange={onChange} value={value || ""} name={name} />
                {error && <Form.Control.Feedback type="invalid">{helperText}</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    </>
}

export default memo(MySingleInput);