import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Department = lazy(() => import('./department'));

const departmentRoutes = [
    {
        exact: true,
        path: "/departments",
        component: Department,
    },
];

export default departmentRoutes;
