import { INSERT_SUCCESS_TEAM, GET_SUCCESS_TEAM } from "../actions/TeamActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    teamArr: [],
    success: false,
    loading: true,
};


const TeamReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_SUCCESS_TEAM:
            return {
                ...state,
                teamArr: action.payload,
                loading: false
            }
        case INSERT_SUCCESS_TEAM:
            return {
                ...state,
                loading: false,
            };
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}

export default TeamReducer;